import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertType } from '../../shared/alert-snack-bar/alert-snack-bar.model';
import { AuthLoginDTO } from '../models/auth.model';
import { Response } from '../models/response.model';
import { AuthStore } from '../store/auth.store';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authStore = inject(AuthStore);
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly tokenService = inject(TokenService);

  readonly loginPayload = signal<{ email: string; password: string } | null>(null);
  readonly resetPasswordPayload = signal<{ token: string; password: string } | null>(null);
  readonly sendEmailResetPasswordPayload = signal<{ email: string } | null>(null);

  readonly loginResultTrigger = signal<boolean>(false);

  private readonly loadingLogin = signal<boolean>(false);
  private readonly loadingResetPassword = signal<boolean>(false);
  private readonly loadingSendEmailResetPassword = signal<boolean>(false);

  readonly isLoadingLogin = computed<boolean>(() => this.loadingLogin());
  readonly isLoadingResetPassword = computed<boolean>(() => this.loadingResetPassword());
  readonly isLoadingSendEmailResetPassword = computed<boolean>(() => this.loadingSendEmailResetPassword());

  readonly loginResult = toSignal<AlertType.Success | AlertType.Error>(
    toObservable(this.loginPayload).pipe(
      filter(loginPayload => loginPayload !== null),
      switchMap(loginPayload => {
        this.loadingLogin.set(true);
        const endpoint = `${environment.api}/auth/login`;
        return this.http.post<Response<AuthLoginDTO>>(endpoint, { username: loginPayload.email, password: loginPayload.password }).pipe(
          map(({ data }) => {
            this.handleSuccessAuth(data);
            return AlertType.Success as AlertType.Success;
          }),
          tap(() => {
            this.loadingLogin.set(false);
            this.loginResultTrigger.update(value => !value);
          }),
          retry(3),
          catchError(error => {
            this.loadingLogin.set(false);
            this.loginResultTrigger.update(value => !value);
            console.error(error);
            return of(AlertType.Error as AlertType.Error);
          }),
        );
      }),
    ),
  );

  readonly sendEmailResetPasswordResult = toSignal<AlertType.Success | AlertType.Error>(
    toObservable(this.sendEmailResetPasswordPayload).pipe(
      filter(sendEmailResetPasswordPayload => sendEmailResetPasswordPayload !== null),
      switchMap(sendEmailResetPasswordPayload => {
        this.loadingSendEmailResetPassword.set(true);
        const endpoint = `${environment.api}/users/reset-password`;
        return this.http.post<Response<boolean>>(endpoint, { ...sendEmailResetPasswordPayload }).pipe(
          map(() => {
            this.loadingSendEmailResetPassword.set(false);
            return AlertType.Success as AlertType.Success;
          }),
          tap(() => this.loadingSendEmailResetPassword.set(false)),
          retry(3),
          catchError(error => {
            this.loadingSendEmailResetPassword.set(false);
            console.error(error);
            return of(AlertType.Error as AlertType.Error);
          }),
        );
      }),
    ),
  );

  readonly resetPasswordResult = toSignal<AlertType.Success | AlertType.Error>(
    toObservable(this.resetPasswordPayload).pipe(
      filter(resetPasswordPayload => resetPasswordPayload !== null),
      switchMap(resetPasswordPayload => {
        this.loadingResetPassword.set(true);
        const endpoint = `${environment.api}/users/reset-password/${resetPasswordPayload.token}`;
        return this.http.post<Response<boolean>>(endpoint, { password: resetPasswordPayload.password }).pipe(
          map(() => {
            return AlertType.Success as AlertType.Success;
          }),
          tap(() => this.loadingResetPassword.set(false)),
          retry(3),
          catchError(error => {
            this.loadingResetPassword.set(false);
            console.error(error);
            return of(AlertType.Error as AlertType.Error);
          }),
        );
      }),
    ),
  );

  logout(): void {
    this.tokenService.deleteTokenAndRefreshToken();
    this.authStore.restoreAuthenticationState();
    this.router.navigate(['/auth/login']);
  }

  isAuthenticated(): boolean {
    const { token } = this.tokenService.getTokenAndRefreshToken();
    return !!token;
  }

  refreshToken(): Observable<{ token: string | null }> {
    const endpoint = `${environment.api}/auth/refresh`;
    const { refreshToken } = this.tokenService.getTokenAndRefreshToken();

    return this.http.post<Response<AuthLoginDTO>>(endpoint, { refresh_token: refreshToken }).pipe(
      map(({ data }) => {
        this.handleSuccessAuth(data);
        return { token: data.access_token };
      }),
      catchError(error => {
        console.error(error);
        return of({ token: null });
      }),
    );
  }

  private handleSuccessAuth(authLoginDTO: AuthLoginDTO): void {
    const tokens = { accessToken: authLoginDTO.access_token, refreshToken: authLoginDTO.refresh_token };
    this.tokenService.setTokenAndRefreshToken(tokens);

    this.authStore.updateAuthenticationState({
      user: {
        firstName: authLoginDTO.user.first_name,
        lastName: authLoginDTO.user.last_name,
        email: authLoginDTO.user.email,
        permissions: authLoginDTO.user.permissions,
        providers: authLoginDTO.user.providers,
        id: authLoginDTO.user.id,
      },
      accessToken: authLoginDTO.access_token,
      refreshToken: authLoginDTO.refresh_token,
    });
  }
}
